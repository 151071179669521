import React, { useState, useContext } from "react";

import EventContext from "../../../store/EventContext";
import classes from "./Tables.module.css";
import EventDetails from "./EventDetails";

const EventList = () => {
  const [event, setEvent] = useState(null);
  const eventCtx = useContext(EventContext);
  return (
    <div>
      <br />
      <br />
      {!event && (
        <table style={{ width: "100%" }} className={classes.table}>
          <thead>
            <tr>
              <th>Event</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {eventCtx.events
              .sort((a, b) => new Date(b.event_date) - new Date(a.event_date))
              .map((evnt) => {
                return (
                  <tr key={evnt.id} onClick={() => setEvent(evnt)}>
                    <td>{`${new Date(evnt.event_date).getFullYear()} ${evnt.event_title}`}</td>
                    <td>{new Date(evnt.event_date).toLocaleDateString()}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}

      <br />
      <br />
      {event && <EventDetails event={event} />}
    </div>
  );
};

export default EventList;
