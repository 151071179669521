import React, { useState } from "react";

import classes from "../../pages/AdminPortal.module.css";

import { IconContext } from "react-icons";
import { IoMdArrowRoundBack } from "react-icons/io";

import Button from "../Button";

import { devAdminUserID } from "../../util/backendUtilities";
import EventList from "./tables/EventList";

const Portal = ({ session }) => {
  const [selection, setSelection] = useState(null);

  return (
    <div>
      {selection && (
        <div
          className={`${classes.flex} ${classes.center}`}
          style={{ cursor: "pointer" }}
        >
          <IconContext.Provider value={{ size: "30px", color: "white" }}>
            <IoMdArrowRoundBack onClick={() => setSelection(null)} />
          </IconContext.Provider>
          <h4>Back</h4>
        </div>
      )}
      {!selection && (
        <div className={`${classes.grid} ${classes.center}`}>
          {devAdminUserID === session.user.id && (
            <Button
              backgroundColor={"#ffa559"}
              shadowColor={"#454545"}
              title={"Lettings"}
            />
          )}
          <Button
            backgroundColor={"#ffa559"}
            shadowColor={"#454545"}
            title={"Events"}
            onClick={() => setSelection(<EventList />)}
          />
          <Button
            backgroundColor={"#ffa559"}
            shadowColor={"#454545"}
            title={"Members"}
          />
        </div>
      )}

      {selection && selection}
    </div>
  );
};

export default Portal;
