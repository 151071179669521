import React, { useContext, useEffect, useState } from "react";

import classes from "../../../pages/AdminPortal.module.css";
import tableClasses from "./Tables.module.css";
import EventContext from "../../../store/EventContext";

const EventDetails = ({ event }) => {
  const { getRegistrants, registrants } = useContext(EventContext);
  const [selectedRegistrant, setSelectedRegistrant] = useState(null);

  useEffect(() => {
    getRegistrants(event.id);
  }, [event.id, getRegistrants]);

  const formatPhoneNumber = (number) => {
    let numberArr = number.split("");
    numberArr.splice(numberArr.length - 4, 0, "-");
    numberArr.splice(numberArr.length - 8, 0, "-");
    return numberArr.reduce((acc, char) => acc + char, "");
  };

  return (
    <div className={classes.page}>
      <h2>{`${event.event_title} ${new Date(
        event.event_date
      ).getFullYear()}`}</h2>
      <h2>{event.event_location}</h2>
      <br />
      <br />
      <table className={tableClasses.table}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Company</th>
          </tr>
        </thead>
        <tbody>
          {registrants.map((reg) => (
            <React.Fragment key={reg.id}>
              <tr
                className={
                  selectedRegistrant === reg.id ? tableClasses.selectedRow : ""
                }
                onClick={() =>
                  setSelectedRegistrant((prev) =>
                    prev === reg.id ? null : reg.id
                  )
                }
              >
                <td>{`${reg.first_name} ${reg.last_name}`}</td>
                <td>{reg.company}</td>
              </tr>
              {selectedRegistrant === reg.id && (
                <React.Fragment>
                  <tr key={reg.id} className={tableClasses.expansionRow}>
                    <td>Email: {reg.email}</td>
                    <td>Phone: {formatPhoneNumber(reg.phone)}</td>
                  </tr>
                  <tr className={tableClasses.expansionRow}>
                    <td>{reg.address}</td>
                    <td>{new Date(reg.created_at).toLocaleDateString()}</td>
                  </tr>
                  {Object.entries(reg.custom_inputs_obj).map(([key, value]) => (
                    <tr
                      key={`${key}${value}`}
                      className={tableClasses.expansionRowCustomInputs}
                    >
                      <td style={{ textAlign: "right" }}>{key}--</td>
                      <td>{value}</td>
                    </tr>
                  ))}
                </React.Fragment>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EventDetails;
