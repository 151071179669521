import React from "react";
import { useContext, useState } from "react";

import formClasses from "../forms/Forms.module.css";
import styleClasses from "./RegistrationForm.module.css";
import UserInfoContext from "../../store/UserInfoContext";
import EventContext from "../../store/EventContext";

import { SpinningCircles } from "react-loading-icons";

import RegisterButton from "./RegisterButton";
import Button from "../Button";
import { useNavigate } from "react-router-dom";
import CheckoutContext from "../../store/CheckoutContext";

const GolfTournament = () => {
  const [sponsorship, setSponsorship] = useState("Platinum Sponsor");
  const [sponsorshipPrice, setSponsorshipPrice] = useState("3000");

  const [isGolfBall, setIsGolfBall] = useState(null);
  const [isGolfTee, setIsGolfTee] = useState(null);
  const [isReception, setIsReception] = useState(null);

  //TEAM 1 Name and emails
  const [t1N1, sett1N1] = useState("");
  const [t1E1, sett1E1] = useState("");
  const [t1N2, sett1N2] = useState("");
  const [t1E2, sett1E2] = useState("");
  const [t1N3, sett1N3] = useState("");
  const [t1E3, sett1E3] = useState("");
  const [t1N4, sett1N4] = useState("");
  const [t1E4, sett1E4] = useState("");
  //TEAM 2 Name and emails
  const [t2N1, sett2N1] = useState("");
  const [t2E1, sett2E1] = useState("");
  const [t2N2, sett2N2] = useState("");
  const [t2E2, sett2E2] = useState("");
  const [t2N3, sett2N3] = useState("");
  const [t2E3, sett2E3] = useState("");
  const [t2N4, sett2N4] = useState("");
  const [t2E4, sett2E4] = useState("");

  const [indName, setIndName] = useState("");
  const [indEmail, setIndEmail] = useState("");

  const [isAttReception, setIsAttReception] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState("");
  const [didComplete, setDidComplete] = useState(false);

  const [payMethod, setPayMethod] = useState(null);

  const userCtx = useContext(UserInfoContext);
  const eventCtx = useContext(EventContext);

  const navigate = useNavigate();
  const checkoutCtx = useContext(CheckoutContext);

  const { format } = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <div className={styleClasses.page}>
      <h1 className={styleClasses.reservationNotice}>
        For hotel reservations at{" "}
        {eventCtx.selectedEvent.custom_info_obj.hotelName} call{" "}
        {eventCtx.selectedEvent.custom_info_obj.hotelPhone} and use group code{" "}
        <b style={{ color: "#ffa559" }}>
          {eventCtx.selectedEvent.custom_info_obj.reservationRoomCode}
        </b>{" "}
        or reserve online
        <a
          // className={styleClasses.disclaimer}
          href={eventCtx.selectedEvent.custom_info_obj.reservationURL}
          target="blank"
          rel="noopener"
        >
          <b> here.</b>
        </a>
        &nbsp;&nbsp;Last day to make reservations is{" "}
        {eventCtx.selectedEvent.custom_info_obj.reservationLastDay}
      </h1>
      <br />
      <h4 className={styleClasses.disclaimer}>
        The proceeds from this event will be contributed to MACPAC, which is a
        political action committee. Contributions to MACPAC are not deductible
        as business expenses. Please consult with your tax advisor concerning
        the tax treatment of contributions to MACPAC.
      </h4>

      <section
        className={` ${styleClasses.section} ${styleClasses.borderOrange}`}
      >
        <h2>Sponsorship</h2>
        <select
          name="sponsorship"
          id="sponsorship"
          onChange={(e) => {
            //USE THE VALUE (WHICH IS THE ARRAY ID) PASSED FROM THE select ELEMENT TO
            // RECONSTRUCT THE SORTED ARRAY OR ARRAYS AND THEN GET THE CORRECT ARRAY THAT
            // WAS ORIGINALLY SELECTED
            const selectedSponsorship = Object.entries(
              eventCtx.selectedEvent.price_obj.sponsorship
            ).sort((a, b) => Number(b[1]) - Number(a[1]))[e.target.value];

            //USE THAT ARRAY TO SET STATE THAT GETS PASSED TO CHECKOUT
            setSponsorship(`${selectedSponsorship[0]} Sponsor`);
            setSponsorshipPrice(selectedSponsorship[1]);
          }}
        >
          {Object.entries(eventCtx.selectedEvent.price_obj.sponsorship)
            .sort((a, b) => Number(b[1]) - Number(a[1])) //Sort price high to low
            .map((arr, index) => {
              return (
                <option
                  key={index}
                  value={index}
                >{`${arr[0]} - $${arr[1]}`}</option>
              );
            })}
        </select>
        <div className={styleClasses.detailLists}>
          <h4>Platinum:</h4>
          <ul>
            {eventCtx.selectedEvent.price_obj.sponsorshipDetails.Platinum.map(
              (detail, index) => (
                <li key={index}>{detail}</li>
              )
            )}
          </ul>
          <h4>Gold:</h4>
          <ul>
            {eventCtx.selectedEvent.price_obj.sponsorshipDetails.Gold.map(
              (detail, index) => (
                <li key={index}>{detail}</li>
              )
            )}
          </ul>
          <h4>Silver:</h4>
          <ul>
            {eventCtx.selectedEvent.price_obj.sponsorshipDetails.Silver.map(
              (detail, index) => (
                <li key={index}>{detail}</li>
              )
            )}
          </ul>
        </div>
      </section>
      <section
        className={` ${styleClasses.section} ${styleClasses.borderOrange}`}
      >
        <h2>Optional Sponorship Opportunities</h2>
        <h3>
          <i>Select all that apply</i>
        </h3>
        <div
          className={`${styleClasses.checkbox}`}
          style={{ marginBottom: "2em" }}
        >
          <input
            type="checkbox"
            name="opt-sponsorship"
            value="Golf Tee"
            onChange={() => setIsGolfTee(true)}
          />
          <label htmlFor="pay-method">
            Golf Tee{" "}
            {format(
              eventCtx.selectedEvent.price_obj.optionalSponsorship.Golf_Tee
            )}
          </label>
          <input
            type="checkbox"
            name="opt-sponsorship"
            value="Golf Ball"
            onChange={() => setIsGolfBall(true)}
          />
          <label htmlFor="pay-method">
            Golf Ball{" "}
            {format(
              eventCtx.selectedEvent.price_obj.optionalSponsorship.Golf_Ball
            )}
          </label>
          <input
            type="checkbox"
            name="opt-sponsorship"
            value="Reception"
            onChange={() => setIsReception(true)}
          />
          <label htmlFor="pay-method">
            Reception{" "}
            {format(
              eventCtx.selectedEvent.price_obj.optionalSponsorship.Reception
            )}
          </label>
        </div>
        <div className={styleClasses.detailLists}>
          <h4>Golf Tee:</h4>
          <ul>
            {eventCtx.selectedEvent.price_obj.optionalSponsorshipDetails.Golf_Tee.map(
              (listItem, index) => (
                <li key={index}>{listItem}</li>
              )
            )}
          </ul>
          <h4>Golf Ball:</h4>
          <ul>
            {eventCtx.selectedEvent.price_obj.optionalSponsorshipDetails.Golf_Ball.map(
              (listItem, index) => (
                <li key={index}>{listItem}</li>
              )
            )}
          </ul>
          <h4>Reception:</h4>
          <ul>
            {eventCtx.selectedEvent.price_obj.optionalSponsorshipDetails.Reception.map(
              (listItem, index) => (
                <li key={index}>{listItem}</li>
              )
            )}
          </ul>
        </div>
      </section>
      <section
        className={`${styleClasses.section} ${styleClasses.borderOrange}`}
      >
        <h2>
          Will you be joining us for the Welcome Reception
        </h2>
        <h3 style={{ margin: "0" }}>
          {eventCtx.selectedEvent.custom_info_obj.welcomeReceptionDate}
        </h3>
        <h3 style={{ margin: "0" }}>
          {eventCtx.selectedEvent.custom_info_obj.welcomeReceptionLocation}
        </h3>
        <br />
        <br />
        <div className={styleClasses.checkbox} style={{ marginBottom: "2em" }}>
          <input
            type="radio"
            name="reception"
            value="Yes"
            onChange={(e) => setIsAttReception(e.target.value)}
          />
          <label htmlFor="pay-method">Yes</label>
          <input
            type="radio"
            name="reception"
            value="No"
            onChange={(e) => setIsAttReception(e.target.value)}
          />
          <label htmlFor="pay-method">No</label>
          <input
            type="radio"
            name="reception"
            value="Maybe"
            onChange={(e) => setIsAttReception(e.target.value)}
          />
          <label htmlFor="pay-method">Maybe</label>
        </div>
      </section>
      <section
        className={`${styleClasses.section} ${styleClasses.borderOrange}`}
      >
        <div
          className={`${styleClasses.backgroundOrange} ${styleClasses.headingBackground}`}
        >
          <h3>{sponsorship.toUpperCase()}</h3>
          {/* //TODO: Setup team registration dynamically based on sponsorship */}
        </div>
        {(sponsorship === "Platinum Sponsor" ||
          sponsorship === "Gold Sponsor") && (
          <>
            <h3>Team 1</h3>
            <div
              className={`${formClasses.gridTwoCol} ${formClasses.gridContainer}`}
            >
              <input
                type="text"
                placeholder="Name"
                onChange={(e) => sett1N1(e.target.value)}
              />
              <input
                type="text"
                placeholder="Email"
                className={formClasses.gridTwo}
                onChange={(e) => sett1E1(e.target.value)}
              />
              <input
                type="text"
                placeholder="Name"
                onChange={(e) => sett1N2(e.target.value)}
              />
              <input
                type="text"
                placeholder="Email"
                className={formClasses.gridTwo}
                onChange={(e) => sett1E2(e.target.value)}
              />
              <input
                type="text"
                placeholder="Name"
                onChange={(e) => sett1N3(e.target.value)}
              />
              <input
                type="text"
                placeholder="Email"
                className={formClasses.gridTwo}
                onChange={(e) => sett1E3(e.target.value)}
              />
              <input
                type="text"
                placeholder="Name"
                onChange={(e) => sett1N4(e.target.value)}
              />
              <input
                type="text"
                placeholder="Email"
                className={formClasses.gridTwo}
                onChange={(e) => sett1E4(e.target.value)}
              />
            </div>
          </>
        )}
        {sponsorship === "Platinum Sponsor" && (
          <>
            <h3>Team 2</h3>
            <div
              className={`${formClasses.gridTwoCol} ${formClasses.gridContainer}`}
            >
              <input
                type="text"
                placeholder="Name"
                onChange={(e) => sett2N1(e.target.value)}
              />
              <input
                type="text"
                placeholder="Email"
                className={formClasses.gridTwo}
                onChange={(e) => sett2E1(e.target.value)}
              />
              <input
                type="text"
                placeholder="Name"
                onChange={(e) => sett2N2(e.target.value)}
              />
              <input
                type="text"
                placeholder="Email"
                className={formClasses.gridTwo}
                onChange={(e) => sett2E2(e.target.value)}
              />
              <input
                type="text"
                placeholder="Name"
                onChange={(e) => sett2N3(e.target.value)}
              />
              <input
                type="text"
                placeholder="Email"
                className={formClasses.gridTwo}
                onChange={(e) => sett2E3(e.target.value)}
              />
              <input
                type="text"
                placeholder="Name"
                onChange={(e) => sett2N4(e.target.value)}
              />
              <input
                type="text"
                placeholder="Email"
                className={formClasses.gridTwo}
                onChange={(e) => sett2E4(e.target.value)}
              />
            </div>
          </>
        )}
        {sponsorship === "Silver Sponsor" && (
          <>
            <h3>Individual Golfer Info</h3>
            <div
              className={`${formClasses.gridTwoCol} ${formClasses.gridContainer}`}
            >
              <input
                type="text"
                placeholder="Name"
                onChange={(e) => setIndName(e.target.value)}
              />
              <input
                type="text"
                placeholder="Email"
                className={formClasses.gridTwo}
                onChange={(e) => setIndEmail(e.target.value)}
              />
            </div>
          </>
        )}
      </section>
      <br />
      <h3>For more information:</h3>
      <a
        href={`https://yplstzafkrlvgcnssbfi.supabase.co/storage/v1/object/public/event_storage/${eventCtx.selectedEvent.event_attachment_filename}`}
        target="about_blank"
      >
        Download the PDF registration packet
      </a>
      <br />
      <br />
      <br />
      {isLoading ? (
        <SpinningCircles stroke="#ffffff30" fill="#FFF" speed="1.0" />
      ) : (
        submitMessage
      )}
      <br />
      <br />
      {!didComplete && (
        <RegisterButton
          setIsLoading={setIsLoading}
          setSubmitMessage={setSubmitMessage}
          didComplete={setDidComplete}
          registrantData={{
            eventID: eventCtx.selectedEvent.id,
            event: eventCtx.selectedEvent,
            company: userCtx.company,
            firstName: userCtx.firstName,
            lastName: userCtx.lastName,
            address: `${userCtx.street} ${userCtx.city} ${userCtx.state} ${userCtx.zip}`,
            email: userCtx.email,
            phone: userCtx.phone,
            isAttendingReception: isAttReception,
            customInputs: {
              Sponsorship: sponsorship,
              GolfBall_Sponsor: `${isGolfBall ? "Yes" : "No"}`,
              GolfTee_Sponsor: `${isGolfTee ? "Yes" : "No"}`,
              Reception_Sponsor: `${isReception ? "Yes" : "No"}`,
              attendingReception: isAttReception,
              [`Team 1: ${t1N1}`]: t1E1,
              [`Team 1: ${t1N2}`]: t1E2,
              [`Team 1: ${t1N3}`]: t1E3,
              [`Team 1: ${t1N4}`]: t1E4,
              [`Team 2: ${t2N1}`]: t2E1,
              [`Team 2: ${t2N2}`]: t2E2,
              [`Team 2: ${t2N3}`]: t2E3,
              [`Team 2: ${t2N4}`]: t2E4,
              [`Individual Golfer: ${indName}`]: indEmail,
            },
          }}
        />
      )}
      {didComplete && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "5em",
          }}
        >
          <h2>Pay Method:</h2>
          <div
            className={styleClasses.checkbox}
            style={{ marginBottom: "2em" }}
          >
            <input
              type="radio"
              name="pay-method"
              value="Check"
              onChange={() => setPayMethod("Check")}
            />
            <label htmlFor="pay-method">Check</label>
            <input
              type="radio"
              name="pay-method"
              value="Card"
              onChange={() => setPayMethod("Card")}
            />
            <label htmlFor="pay-method">Card</label>
          </div>
          {payMethod === "Check" && (
            <div style={{ marginBottom: "5em" }}>
              <h3>Please Mail Check to:</h3>
              <p style={{ margin: "0", textAlign: "center" }}>
                Mississippi Asphalt Pavement Association
              </p>
              <p style={{ margin: "0", textAlign: "center" }}>
                711 N. Presidents St.
              </p>
              <p style={{ margin: "0", textAlign: "center" }}>
                Jackson, MS 39202
              </p>
            </div>
          )}
          {payMethod && (
            <Button
              backgroundColor={"#FFA559"}
              shadowColor={"#454545"}
              title={payMethod === "Check" ? "Okay" : "Proceed to Checkout"}
              onClick={() => {
                if (payMethod === "Check") {
                  navigate("/");
                } else {
                  checkoutCtx.addItem({
                    itemName: sponsorship,
                    itemPrice: sponsorshipPrice,
                  });
                  if (isGolfTee) {
                    checkoutCtx.addItem({
                      itemName: "Golf Tee Sponsor",
                      itemPrice:
                        eventCtx.selectedEvent.price_obj.optionalSponsorship
                          .Golf_Tee,
                    });
                  }
                  if (isGolfBall) {
                    checkoutCtx.addItem({
                      itemName: "Golf Ball Sponsor",
                      itemPrice:
                        eventCtx.selectedEvent.price_obj.optionalSponsorship
                          .Golf_Ball,
                    });
                  }
                  if (isReception) {
                    checkoutCtx.addItem({
                      itemName: "Reception Sponsor",
                      itemPrice:
                        eventCtx.selectedEvent.price_obj.optionalSponsorship
                          .Reception,
                    });
                  }

                  navigate("/checkout");
                }
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default GolfTournament;
