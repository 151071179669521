import { createContext, useCallback, useEffect, useState } from "react";
import axios from "axios";
import { siteIP } from "../util/backendUtilities";

const EventContext = createContext({
  isLoading: false,
  setIsLoading: () => {},
  events: [],
  setEvents: () => {},
  selectedEvent: {},
  setSelectedEvent: () => {},
  registrants: [],
  setRegistrants: () => {},
  getRegistrants: () => {},
});

export const EventContextProvider = (props) => {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [registrants, setRegistrants] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    const date = new Date();
    axios.get(`${siteIP}/get-events:${date}`).then((res) => {
      setEvents(res.data);
      setIsLoading(false);
    });
  }, []);

  const getRegistrants = useCallback((eventId) => {
    axios.get(`${siteIP}/get-registrants/${eventId}`).then((res) => {
      setRegistrants(res.data);
    });
  }, []);

  const contextValue = {
    isLoading,
    setIsLoading,
    events,
    setEvents,
    selectedEvent,
    setSelectedEvent,
    registrants,
    setRegistrants,
    getRegistrants,
  };

  return (
    <EventContext.Provider value={contextValue}>
      {props.children}
    </EventContext.Provider>
  );
};

export default EventContext;
